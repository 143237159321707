import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isNil, noop, isEmpty } from 'lodash';
import { Button, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { CodeSelect, ConsultantSelect, DateInput } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Input from './Common/Input';
import ProductSelect from './Common/ProductSelect';
import OrderOriginSelect from './Common/OrderOriginSelect';
import CustomerTaskTypeSelect from './Common/CustomerTaskTypeSelect';
import leadMassMutationActions from '../../../actions/LeadMassMutation/Actions';

export const APPOINTMENT_COMMISSIONED_STATUS = 'customerTaskStatus-appointmentCommissioned';
export const APPOINTMENT_COMMISSIONED_POOL_USER = '6300UV9989';

class LeadMassMutationEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.onConsultantChange = this.onConsultantChange.bind(this);
    this.onOrderOriginChange = this.onOrderOriginChange.bind(this);
    this.onCustomerTaskStatusChange = this.onCustomerTaskStatusChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  /**
   * StaffMemberId and additionalEditor are only ids, not whole consultant object.
   * Store them as id to make update easier
   *
   * @param   {String}  key        Form element key
   * @param   {Object}  consulant  Selected consultant
   *
   * @return  void
   */
  onConsultantChange(key, consulant) {
    const { leadMassMutationActions } = this.props;

    leadMassMutationActions.editFormSetValue(key, get(consulant, 'id'));
  }

  /**
   * Handle orderOrigin change.
   * Reset customerTaskType to undefined
   *
   * @param   {String} key    Form element key
   * @param   {Array}  value  Selected value(s)
   *
   * @return  void
   */
  onOrderOriginChange(key, value) {
    const { leadMassMutationActions } = this.props;

    leadMassMutationActions.editFormSetValue(key, value);
    leadMassMutationActions.editFormSetValue('customerTaskType');
  }

  /**
   * Handle customerTaskType change
   *
   * @param   {[type]}  key    [key description]
   * @param   {[type]}  value  [value description]
   *
   * @return  {[type]}         [return description]
   */
  onCustomerTaskStatusChange(key, value) {
    const {
      consultants,
      leadMassMutationActions
    } = this.props;
    // Only set if a status change happened
    const lastStatusChangeAt = isNil(value)
      ? undefined
      : moment().format();

    leadMassMutationActions.editFormSetValue(key, value);
    leadMassMutationActions.editFormSetValue('additionalEditor');
    leadMassMutationActions.editFormSetValue('lastStatusChangeAt', lastStatusChangeAt);

    // Update additional editor if status changes to appointmentCommissioned
    // https://issue.swisscom.ch/browse/MAP-8361
    if (get(value, 'id') === APPOINTMENT_COMMISSIONED_STATUS) {
      const consultant = get(consultants, 'data', []).find((c) => c.username === APPOINTMENT_COMMISSIONED_POOL_USER);
      leadMassMutationActions.editFormSetValue('additionalEditor', get(consultant, 'id'));
    }
  }

  /**
   * Submit the form
   *
   * @return  void
   */
  onSubmit() {
    const {
      editForm,
      onSubmit
    } = this.props;

    onSubmit({ formData: get(editForm, 'data', {}) });
  }

  /**
   * Reset the form
   *
   * @return  void
   */
  onReset() {
    const { leadMassMutationActions } = this.props;

    leadMassMutationActions.editFormReset();
  }

  /**
   * Get a consultant by id
   *
   * @param   {String}  id  Consultant id
   *
   * @return  {Object} consultant Consultant
   */
  getConsultantById(id) {
    const { consultants } = this.props;

    return get(consultants, 'data', []).find((c) => c.id === id);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      codeMappings,
      codes,
      config,
      consultants,
      disabled,
      editForm,
      language,
      leadMassMutationActions,
      products
    } = this.props;

    return (
      <div className="lead-mass-mutation--search-form">
        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.OrderOrigin" />
              </ControlLabel>

              <OrderOriginSelect
                id="orderOrigin"
                codeMappings={codeMappings}
                codes={codes}
                language={language}
                onChange={this.onOrderOriginChange}
                value={get(editForm, 'data.orderOrigin')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.CustomerTaskType" />
              </ControlLabel>

              <CustomerTaskTypeSelect
                id="customerTaskType"
                codes={codes}
                config={config}
                language={language}
                onChange={leadMassMutationActions.editFormSetValue}
                orderOrigin={get(editForm, 'data.orderOrigin')}
                value={get(editForm, 'data.customerTaskType')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.CustomerTaskStatus" />
              </ControlLabel>

              <CodeSelect
                id="customerTaskStatus"
                codes={get(codes, 'groups.customerTaskStatus')}
                language={language}
                onChange={this.onCustomerTaskStatusChange}
                value={get(editForm, 'data.customerTaskStatus')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.Product" />
              </ControlLabel>

              <ProductSelect
                codeMappings={codeMappings}
                id="successProduct"
                language={language}
                onChange={leadMassMutationActions.editFormSetValue}
                products={products}
                value={get(editForm, 'data.successProduct')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.StaffMember" />
              </ControlLabel>

              <ConsultantSelect
                consultants={get(consultants, 'data', [])}
                id="staffMemberId"
                onChange={this.onConsultantChange}
                useFuse
                value={this.getConsultantById(get(editForm, 'data.staffMemberId'))}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.AdditionalEditor" />
              </ControlLabel>

              <ConsultantSelect
                consultants={get(consultants, 'data', [])}
                id="additionalEditor"
                onChange={this.onConsultantChange}
                useFuse
                value={this.getConsultantById(get(editForm, 'data.additionalEditor'))}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.DueDate" />
              </ControlLabel>

              <DateInput
                id="dueDate"
                onChange={leadMassMutationActions.editFormSetValue}
                value={get(editForm, 'data.dueDate')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.ExpireDate" />
              </ControlLabel>

              <DateInput
                id="expireDate"
                onChange={leadMassMutationActions.editFormSetValue}
                value={get(editForm, 'data.expireDate')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.EditForm.LeadInstruction" />
              </ControlLabel>

              <Input
                id="leadinstruction"
                componentClass="textarea"
                onChange={leadMassMutationActions.editFormSetValue}
                type="textarea"
                value={get(editForm, 'data.leadinstruction')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} style={{ textAlign: 'right' }}>
            <Button
              disabled={disabled || isEmpty(editForm.data)}
              bsStyle="primary"
              onClick={this.onSubmit}
            >
              <FormattedMessage id="LeadMassMutation.EditForm.Submit" />
            </Button>

            <span style={{ paddingRight: '0.5em' }} />

            <Button onClick={this.onReset}>
              <FormattedMessage id="LeadMassMutation.EditForm.Reset" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

LeadMassMutationEditForm.propTypes = {
  codeMappings: PropTypes.object,
  codes: PropTypes.object,
  config: PropTypes.object,
  consultants: PropTypes.object,
  disabled: PropTypes.bool,
  editForm: PropTypes.object,
  language: PropTypes.string,
  leadMassMutationActions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  products: PropTypes.object
};

LeadMassMutationEditForm.defaultProps = {
  codeMappings: {},
  codes: { groups: {} },
  config: {},
  consultants: {},
  disabled: false,
  editForm: {},
  language: 'de',
  onSubmit: noop,
  products: {}
};

function mapStateToProps(state, ownProps) {
  return {
    codeMappings: state.codeMapping.mappings,
    codes: state.codes,
    config: state.leadMassMutation.config,
    consultants: state.leadMassMutation.consultants,
    editForm: state.leadMassMutation.editForm,
    language: state.login.language,
    products: state.leadMassMutation.products,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leadMassMutationActions: bindActionCreators(leadMassMutationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadMassMutationEditForm);
