import React from 'react';
import PropTypes from 'prop-types';
import { get, isNil, noop } from 'lodash';
import update from 'immutability-helper';
import { Row } from 'react-bootstrap';
import { Checkbox } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import './HeaderRow.css';
import HeaderCol from './HeaderCol';

class LeadMassMutationTableHeaderRow extends React.Component {
  constructor(props) {
    super(props);

    this.onSortChange = this.onSortChange.bind(this);
  }

  /**
   * Handle sort direction change of the given col
   *
   * @param   {String}  id         Column id
   * @param   {String}  direction  Sort direction
   *
   * @return  void
   */
  onSortChange(id, sort) {
    const {
      onSortChange,
      sortOrder
    } = this.props;

    const updated = isNil(sort)
      ? update(sortOrder, { $unset: [id] })
      : update(sortOrder, { [id]: { $set: sort } });

    onSortChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      allLeadsSelected,
      consultants,
      language,
      onSelectAll,
      sortOrder
    } = this.props;

    return (
      <Row className="lead-mass-mutation--table--header-row">
        <HeaderCol
          id="customerNumber"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath="customer.number"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              id="selectAll"
              onChange={onSelectAll}
              value={allLeadsSelected}
            />

            <FormattedMessage id="LeadMassMutation.List.CustomerNumber" />
          </div>
        </HeaderCol>

        <HeaderCol
          id="customerDesignation"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath="customer.designation"
          lg={2}
          md={2}
        >
          <FormattedMessage id="LeadMassMutation.List.Customer" />
        </HeaderCol>

        <HeaderCol
          id="consultantFirstName"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath="consultantFirstName"
          lg={2}
          md={2}
        >
          <FormattedMessage id="LeadMassMutation.List.Consultant" />
        </HeaderCol>

        <HeaderCol
          id="staffMember"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath={(lead) => get(consultants, 'data', []).find((c) => c.id === get(lead, 'staffMemberId'))}
          lg={2}
          md={2}
        >
          <FormattedMessage id="LeadMassMutation.List.StaffMember" />
        </HeaderCol>

        <HeaderCol
          id="orderOrigin"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath={`orderOrigin.text.${language}`}
        >
          <FormattedMessage id="LeadMassMutation.List.OrderOrigin" />
        </HeaderCol>

        <HeaderCol
          id="customerTaskType"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath={`customerTaskType.text.${language}`}
        >
          <FormattedMessage id="LeadMassMutation.List.CustomerTaskType" />
        </HeaderCol>

        <HeaderCol
          id="customerTaskStatus"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath={`customerTaskStatus.text.${language}`}
        >
          <FormattedMessage id="LeadMassMutation.List.CustomerTaskStatus" />
        </HeaderCol>

        <HeaderCol
          id="dueDate"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath="dueDate"
        >
          <FormattedMessage id="LeadMassMutation.List.DueDate" />
        </HeaderCol>

        <HeaderCol
          id="expireDate"
          onSortChange={this.onSortChange}
          sortOrder={sortOrder}
          sortPath="expireDate"
        >
          <FormattedMessage id="LeadMassMutation.List.ExpireDate" />
        </HeaderCol>
      </Row>
    );
  }
}

LeadMassMutationTableHeaderRow.propTypes = {
  allLeadsSelected: PropTypes.bool,
  consultants: PropTypes.object,
  language: PropTypes.string,
  onSelectAll: PropTypes.func,
  onSortChange: PropTypes.func,
  sortOrder: PropTypes.object
};

LeadMassMutationTableHeaderRow.defaultProps = {
  allLeadsSelected: false,
  consultants: {},
  language: 'de',
  onSelectAll: noop,
  onSortChange: noop,
  sortOrder: {}
};

export default LeadMassMutationTableHeaderRow;
