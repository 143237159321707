import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import PrivateRoute from './components/Router/PrivateRoute';
import Layout from './pages/Layout';
import Agenda from './pages/Agenda';
import Banklist from './pages/Banklist';
import Checklist from './pages/Checklist';
import Checkpoints from './pages/Checkpoints';
import CoreConfig from './pages/CoreConfig';
import { ConsultantsPage } from './pages/ConsultantsPage';
import Documents from './pages/Documents';
import Types from './pages/Types';
import ExternalLink from './pages/ExternalLink';
import FinancingLoanProducts from './pages/FinancingLoanProducts';
import FinancingSurchargeLendingrate from './pages/FinancingSurchargeLendingrate';
import FinancingSurchargeRealestatetype from './pages/FinancingSurchargeRealestatetype';
import InvestmentProduct from './pages/InvestmentProduct';
import { Overview as ParamTransferOverview } from './packages/ParamTransfer/index';
import Promotions from './pages/Promotions';
import LeadMassMutation from './pages/LeadMassMutation';
import Leadqualification from './pages/Leadqualification';
import LeadSwitch from './pages/LeadSwitch';
import { Overview as ModuleOverview } from './packages/Module/index';
import Workguide from './pages/Workguide';
import WorkguideDetail from './pages/WorkguideDetail';
import BasicProducts from './pages/BasicProducts';
import WorkguideList from './pages/WorkguideList';
import WorkguideCodes from './pages/WorkguideCodes';
import CustomerBook from './pages/CustomerBook';
import Interests from './pages/Interests';
import WorkguideStatus from './pages/WorkguideStatus';
import { HolidaysPage } from './pages/HolidaysPage';
import { FinancingRatesPage } from './pages/FinancingRatesPage';
import { AnalyticsPage } from './pages/AnalyticsPage';
import { AnalyticsLogsPage } from './pages/AnalyticsLogsPage';
import { AnalyticsUserSummaryPage } from './pages/AnalyticsUserSummaryPage';
import NoticeImportPage from './pages/NoticeImportPage';
import { ApiClientPage } from './pages/ApiClientPage';
import { BulkLoadsPage } from './pages/BulkLoadsPage';
import { RefErrorsPage } from './pages/RefErrorsPage';
import { UiNotesPage } from './pages/UiNotesPage';
import { EmailSubscriptionsPage } from './pages/EmailSubscriptionsPage';
import { SetManagementPage } from './pages/SetManagementPage';
import { FinancingProfilingPage } from './pages/FinancingProfilingPage';
import { PermissionGroupPage } from './pages/PermissionGroupPage';
import { JobProcessingPage } from './pages/JobProcessingPage';
import EventstatusPage from './pages/EventstatusPage';
import CodeMappingOverview from './pages/CodeMapping/Overview';
import ReplayAuditLog from './pages/ReplayAuditLog';
import TranslationsPage from './pages/TranslationsPage';
import Textblock from './pages/Textblock';
import FeatureFlagsPage from './pages/FeatureFlagsPage';
import LeadHistoryPage from './pages/LeadHistoryPage';

const Routes = React.memo(({}) => {
  return (
    <Layout>
      <Switch>
        <PrivateRoute exact path="/agenda" component={Agenda} name="agenda" />
        <PrivateRoute exact path="/banklist" component={Banklist} name="banklist" />
        <PrivateRoute exact path="/consultants/:id?" component={ConsultantsPage} name="consultants" />
        <PrivateRoute exact path="/checklist" component={Checklist} name="checklist" />
        <PrivateRoute exact path="/checkpoints/:module?" component={Checkpoints} name="checkpoints" />
        <PrivateRoute exact path="/coreconfig" component={CoreConfig} name="coreconfig" />
        <PrivateRoute exact path="/documents" component={Documents} name="documents" />
        <PrivateRoute exact path="/externallinks" component={ExternalLink} name="externallinks" />
        <PrivateRoute exact path="/permission-group" component={PermissionGroupPage} name="permission-group" />
        <PrivateRoute
          exact
          path="/financingloanproducts"
          component={FinancingLoanProducts}
          name="financingloanproducts"
        />
        <PrivateRoute exact path="/surchargelendingrates" component={FinancingSurchargeLendingrate} name="surchargelendingrates" />
        <PrivateRoute exact path="/investmentproducts" component={InvestmentProduct} name="investmentproducts" />
        <PrivateRoute exact path="/leadqualification" component={Leadqualification} name="leadqualification" />
        <PrivateRoute exact path="/leadswitch" component={LeadSwitch} name="leadswitch" />
        <PrivateRoute exact path="/promotions" component={Promotions} name="promotions" />
        <PrivateRoute exact path="/textblocks" component={Textblock} name="textblocks" />
        <PrivateRoute exact path="/paramTransfer" component={ParamTransferOverview} name="paramTransfer" />
        <PrivateRoute exact path="/types/:type?" component={Types} name="types" />
        <PrivateRoute exact path="/workguide" component={Workguide} name="workguide" />
        <PrivateRoute exact path="/workguideList" component={WorkguideList} name="workguideList" />
        <PrivateRoute exact path="/workguideCodes" component={WorkguideCodes} name="workguideCodes" />
        <PrivateRoute exact path="/workguidedetail/:workguideId" component={WorkguideDetail} name="workguidedetail" />
        <PrivateRoute exact path="/provision-workguide-status" component={WorkguideStatus} name="provision-workguide-status" />
        <PrivateRoute exact path="/basic-products" component={BasicProducts} name="basic-products" />
        <PrivateRoute
          exact
          path="/surcharge-realestatetype"
          component={FinancingSurchargeRealestatetype}
          name="surcharge-realestatetype"
        />
        <PrivateRoute exact path="/customerbook" component={CustomerBook} name="customerbook" />
        <PrivateRoute exact path="/interests" component={Interests} name="interests" />
        <PrivateRoute exact path="/holidays/:listId?" component={HolidaysPage} name="holidays" />
        <PrivateRoute exact path="/financing-rates" component={FinancingRatesPage} name="financing-rates" />
        <PrivateRoute exact path="/analytics/:page?" component={AnalyticsPage} name="analytics" />
        <PrivateRoute exact path="/analytics-logs" component={AnalyticsLogsPage} name="analytics-logs" />
        <PrivateRoute exact path="/analytics-usersummary" component={AnalyticsUserSummaryPage} name="analytics-usersummary" />
        <PrivateRoute exact path="/leads-change" component={LeadMassMutation} name="leads-change" />
        <PrivateRoute exact path="/notice-import" component={NoticeImportPage} name="notice-import" />
        <PrivateRoute exact path="/api-client" component={ApiClientPage} name="api-client" />
        <PrivateRoute exact path="/bulkloads/:loadId?" component={BulkLoadsPage} name="bulkloads" />
        <PrivateRoute exact path="/ref-errors" component={RefErrorsPage} name="ref-errors" />
        <PrivateRoute exact path="/uinotes/:id?" component={UiNotesPage} name="uinotes" />
        <PrivateRoute exact path="/email-subscriptions" component={EmailSubscriptionsPage} name="email-subscriptions" />
        <PrivateRoute exact path="/job-processing" component={JobProcessingPage} name="job-processing" />
        <PrivateRoute exact path="/eventstatus" component={EventstatusPage} name="eventstatus" />
        <PrivateRoute exact path="/set-management" component={SetManagementPage} name="set-management" />
        <PrivateRoute exact path="/financing-profiling" component={FinancingProfilingPage} name="financing-profiling" />
        <PrivateRoute exact path="/code-mapping" component={CodeMappingOverview} name="code-mapping-overview" />
        <PrivateRoute exact path="/auditlog/replay" component={ReplayAuditLog} name="replay-audit-log" />
        <PrivateRoute exact path="/translations" component={TranslationsPage} name="translations" />
        <PrivateRoute exact path="/featureflags" component={FeatureFlagsPage} name="featureflags" />
        <PrivateRoute exact path="/lead-history" component={LeadHistoryPage} name="lead-history" />
        <Route component={ModuleOverview} />
      </Switch>
    </Layout>
  );
});

Routes.propTypes = {

};

Routes.defaultProps = {

};

export default withRouter(Routes);
