import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isEmpty } from 'lodash';
import { Row, Col, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import { CodeSelect, ConsultantSelect, CustomerSearch, DateInput, Select } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import Input from './Common/Input';
import ProductSelect from './Common/ProductSelect';
import OrderOriginSelect from './Common/OrderOriginSelect';
import CustomerTaskTypeSelect from './Common/CustomerTaskTypeSelect';
import leadMassMutationActions from '../../../actions/LeadMassMutation/Actions';

class LeadMassMutationSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.onOrderOriginChange = this.onOrderOriginChange.bind(this);
    this.onIdsPaste = this.onIdsPaste.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  /**
   * Handle orderOrigin change.
   * Reset customerTaskType to undefined
   *
   * @param   {String} key    Form element key
   * @param   {Array}  value  Selected value(s)
   *
   * @return  void
   */
  onOrderOriginChange(key, value) {
    const { leadMassMutationActions } = this.props;

    leadMassMutationActions.searchFormSetValue(key, value);
    leadMassMutationActions.searchFormSetValue('customerTaskType');
  }

  /**
   * Submit the form
   *
   * @return  void
   */
  onSubmit() {
    const {
      onSubmit,
      searchForm
    } = this.props;

    onSubmit({ formData: get(searchForm, 'data', {}) });
  }

  /**
   * Reset the form
   *
   * @return  void
   */
  onReset() {
    const { leadMassMutationActions } = this.props;

    leadMassMutationActions.searchFormReset();
  }

  /**
   * Handle paste into ids field.
   * We asume that it is copy / paste from excel...
   *
   * @param {Event} ev Paste event
   *
   * @return  void
   */
  onIdsPaste(ev) {
    const {
      leadMassMutationActions,
      searchForm
    } = this.props;

    ev.preventDefault();

    const text = (ev.clipboardData || window.clipboardData).getData('text');
    const ids = text
      .split('\n')
      .map((p = '') => p.trim())
      .filter((p) => get(p, 'length', 0) > 0)
      .join(',');

    const current = get(searchForm, 'data.ids.length', 0) > 0
      ? `${get(searchForm, 'data.ids', '')},`
      : '';
    const content = `${current}${ids}`;

    leadMassMutationActions.searchFormSetValue('ids', content);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      codeMappings,
      codes,
      config,
      consultants,
      language,
      leadMassMutationActions,
      locations,
      products,
      regions,
      searchForm
    } = this.props;

    const locationOptions = get(locations, 'data', []).map((l) => ({ value: l.id, label: l.name, location: l }));
    const regionOptions = get(regions, 'data', []).map((r) => ({ value: r.id, label: r.description, region: r }));

    return (
      <div className="lead-mass-mutation--search-form">
        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.Ids" />
              </ControlLabel>

              <Input
                componentClass="textarea"
                id="ids"
                type="textarea"
                onChange={leadMassMutationActions.searchFormSetValue}
                onPaste={this.onIdsPaste}
                value={get(searchForm, 'data.ids')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.Customer" />
              </ControlLabel>

              <CustomerSearch
                id="customer"
                isClearable
                multi
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.customer')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.OrderOrigin" />
              </ControlLabel>

              <OrderOriginSelect
                id="orderOrigin"
                codeMappings={codeMappings}
                codes={codes}
                language={language}
                multi
                onChange={this.onOrderOriginChange}
                value={get(searchForm, 'data.orderOrigin')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.CustomerTaskType" />
              </ControlLabel>

              <CustomerTaskTypeSelect
                id="customerTaskType"
                codes={codes}
                config={config}
                language={language}
                multi
                onChange={leadMassMutationActions.searchFormSetValue}
                orderOrigin={get(searchForm, 'data.orderOrigin')}
                value={get(searchForm, 'data.customerTaskType')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.Consultant" />
              </ControlLabel>

              <ConsultantSelect
                consultants={get(consultants, 'data', [])}
                id="consultant"
                multi
                onChange={leadMassMutationActions.searchFormSetValue}
                useFuse
                value={get(searchForm, 'data.consultant')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.Status" />
              </ControlLabel>

              <CodeSelect
                codes={get(codes, 'groups.customerTaskStatus', [])}
                id="customerTaskStatus"
                language={language}
                multi
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.customerTaskStatus')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.Location" />
              </ControlLabel>

              <Select
                id="location"
                options={locationOptions}
                multi
                onChange={leadMassMutationActions.searchFormSetValue}
                returnValue="location"
                value={get(searchForm, 'data.location', []).map((l) => l.id)}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.LeadInstruction" />
              </ControlLabel>

              <Input
                id="leadinstruction"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.leadinstruction')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.Region" />
              </ControlLabel>

              <Select
                id="region"
                options={regionOptions}
                multi
                onChange={leadMassMutationActions.searchFormSetValue}
                returnValue="region"
                value={get(searchForm, 'data.region', []).map((r) => r.id)}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.ExternalReference" />
              </ControlLabel>

              <Input
                id="externalReference"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.externalReference')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.DueDateFrom" />
              </ControlLabel>

              <DateInput
                id="dueDateFrom"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.dueDateFrom')}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={3}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.DueDateTo" />
              </ControlLabel>

              <DateInput
                id="dueDateTo"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.dueDateTo')}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={3}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.ExpireDateFrom" />
              </ControlLabel>

              <DateInput
                id="expireDateFrom"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.expireDateFrom')}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={3}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.ExpireDateTo" />
              </ControlLabel>

              <DateInput
                id="expireDateTo"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.expireDateTo')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.PublishDateFrom" />
              </ControlLabel>

              <DateInput
                id="publishDateFrom"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.publishDateFrom')}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={3}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.PublishDateTo" />
              </ControlLabel>

              <DateInput
                id="publishDateTo"
                onChange={leadMassMutationActions.searchFormSetValue}
                value={get(searchForm, 'data.publishDateTo')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="LeadMassMutation.SearchForm.Product" />
              </ControlLabel>

              <ProductSelect
                codeMappings={codeMappings}
                id="successProduct"
                language={language}
                multi
                onChange={leadMassMutationActions.searchFormSetValue}
                products={products}
                value={get(searchForm, 'data.successProduct', [])}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} style={{ textAlign: 'right' }}>
            <Button
              disabled={isEmpty(searchForm.data)}
              bsStyle="primary"
              onClick={this.onSubmit}
            >
              <FormattedMessage id="LeadMassMutation.SearchForm.Submit" />
            </Button>

            <span style={{ paddingRight: '0.5em' }} />

            <Button onClick={this.onReset}>
              <FormattedMessage id="LeadMassMutation.SearchForm.Reset" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

LeadMassMutationSearchForm.propTypes = {
  codeMappings: PropTypes.object,
  codes: PropTypes.object,
  config: PropTypes.object,
  consultants: PropTypes.object,
  language: PropTypes.string,
  locations: PropTypes.object,
  leadMassMutationActions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  products: PropTypes.object,
  regions: PropTypes.object,
  searchForm: PropTypes.object
};

LeadMassMutationSearchForm.defaultProps = {
  codeMappings: {},
  codes: { groups: {} },
  config: {},
  consultants: {},
  language: 'de',
  locations: {},
  onSubmit: noop,
  products: {},
  regions: {},
  searchForm: {}
};

function mapStateToProps(state) {
  return {
    codeMappings: state.codeMapping.mappings,
    codes: state.codes,
    config: state.leadMassMutation.config,
    consultants: state.leadMassMutation.consultants,
    language: state.login.language,
    locations: state.leadMassMutation.locations,
    products: state.leadMassMutation.products,
    regions: state.leadMassMutation.regions,
    searchForm: state.leadMassMutation.searchForm
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leadMassMutationActions: bindActionCreators(leadMassMutationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  LeadMassMutationSearchForm
);
