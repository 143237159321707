import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get, chain } from 'lodash';

import productGroupsAction from '../../actions/LeadMassMutation/Products';

const {
  PRODUCTS_PENDING,
  PRODUCTS_FULFILLED,
  PRODUCTS_REJECTED
} = productGroupsAction;

export default function* getProducts() {
  yield put({ type: PRODUCTS_PENDING });

  try {
    const query = QueryBuilder()
      .limit(1000)
      .getQueryString();

    const url = `/basic/productgroup/${query}`;
    const groups = yield call(handleNextHeaders, url);
    const payload = chain(groups)
      .map((g) => get(g, 'products', []))
      .flatten()
      .uniqBy('id')
      .value();

    yield put({ type: PRODUCTS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: PRODUCTS_REJECTED, error });

    return error;
  }
}
