import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loginReducer, logoutReducer } from '@evoja-web/react-login';
import { reducers as textblockReducers } from '@evoja-web/react-core-textblock';
import { reducers as applicationReleaseReducers } from '@evoja-web/react-application-release';

import { reducers as moduleReducers } from '../packages/Module/index';
import { reducers as paramTransferReducers } from '../packages/ParamTransfer/index';

import featureFlagReducers from './FeatureFlag/Reducers';
import AgendaReducer from './AgendaReducer';
import BanklistReducer from './BanklistReducer';
import ChecklistReducer from './ChecklistReducer';
import CheckpointReducer from './CheckpointReducer';
import CodeReducer from './CodeReducer';
import CoreConfigReducer from './CoreConfigReducer';
import DocumentReducer from './DocumentReducer';
import EnvironmentReducer from './Environment';
import ExternalLinkReducer from './ExternalLinkReducer';
import FinancingLoanProducts from './FinancingLoanProductReducer';
import FinancingSurchargeLendingrate from './FinancingSurchargeLendingrateReducer';
import gravitonReducers from './Graviton/Reducers';
import InterestReducer from './InterestReducer';
import InvestmentProduct from './InvestmentProductReducer';
import JobProcessingReducer from './JobProcessingReducer';
import LeadMassMutationReducers from './LeadMassMutation/Reducers';
import LeadSwitch from './LeadSwitch';
import LeadHistoryReducer from './LeadHistory/Reducers';
import Leadqualification from './Leadqualification';
import TypeReducer from './TypeReducer';
import WorkguideReducer from './WorkguideReducer';
import codeMappingsReducer from './CodeMappingReducer';
import loaderTriggerReducer from './LoaderTriggerReducer';
import replayAuditLogReducer from './ReplayAuditLogReducer';
import toastReducer from './ToastReducer';
import { AnalyticsLogsReducer } from './AnalyticsLogsReducer';
import { AnalyticsReducer } from './AnalyticsReducer';
import { AnalyticsUserSummaryReducer } from './AnalyticsUserSummaryReducer';
import { ApiClientReducer } from './ApiClientReducer';
import { BasicProductsReducer } from './BasicProductsReducer';
import { BulkLoadsReducer } from './BulkLoadsReducer';
import { CommonReducer } from './CommonReducer';
import { EmailSubscriptionsReducer } from './EmailSubscriptionsReducer';
import { FinancingProfilingReducer } from './FinancingProfiling/FinancingProfilingReducer';
import { FinancingRatesReducer } from './FinancingRates/FinancingRatesReducer';
import { FinancingSurchargeRealestatetypeReducer } from './FinancingSurchargeRealestatetypeReducer';
import { HolidaysReducer } from './Holidays/HolidaysReducer';
import { InitReducer } from './InitReducer';
import { LeadsSwitchImportCsvReducer } from './LeadsSwitchImport/LeadsSwitchImportCsvReducer';
import { LeadsSwitchImportImportReducer } from './LeadsSwitchImport/LeadsSwitchImportImportReducer';
import { NoticeImportDeleteReducer } from './NoticeImport/NoticeImportDeleteReducer';
import { NoticeImportExamplesReducer } from './NoticeImport/NoticeImportExamplesReducer';
import { NoticeImportFileListLoadReducer } from './NoticeImport/NoticeImportFileListLoadReducer';
import { NoticeImportFilePostReducer } from './NoticeImport/NoticeImportFilePostReducer';
import { NoticeImportImportReducer } from './NoticeImport/NoticeImportImportReducer';
import { NoticeImportValidationReducer } from './NoticeImport/NoticeImportValidationReducer';
import { PermissionGroupReducer } from './PermissionGroupReducer';
import { PromotionsReducer } from './PromotionsReducer';
import { RefErrorsReducer } from './RefErrorsReducer';
import { SetManagementReducer } from './SetManagementReducer';
import { WorkguideStatusReducer } from './WorkguideStatusReducer';
import { consultantsReducer } from './Consultants/consultantsReducer';
import { uiNotesReducer } from './UiNotes/uiNotesReducer';

const rootReducer = combineReducers({
  agenda: AgendaReducer,
  analytics: AnalyticsReducer,
  analyticsLogs: AnalyticsLogsReducer,
  analyticsUserSummary: AnalyticsUserSummaryReducer,
  apiClient: ApiClientReducer,
  applicationRelease: applicationReleaseReducers,
  bankList: BanklistReducer,
  basicProducts: BasicProductsReducer,
  bulkLoads: BulkLoadsReducer,
  checklist: ChecklistReducer,
  checkpoints: CheckpointReducer,
  codeMapping: codeMappingsReducer,
  codes: CodeReducer,
  common: CommonReducer,
  config: InitReducer,
  consultants: consultantsReducer,
  coreConfig: CoreConfigReducer,
  documents: DocumentReducer,
  emailSubscriptions: EmailSubscriptionsReducer,
  environment: EnvironmentReducer,
  externalLinks: ExternalLinkReducer,
  featureFlags: featureFlagReducers,
  financingLoanProducts: FinancingLoanProducts,
  financingProfiling: FinancingProfilingReducer,
  financingRates: FinancingRatesReducer,
  financingSurchargeLendingrate: FinancingSurchargeLendingrate,
  financingSurchargeRealestatetype: FinancingSurchargeRealestatetypeReducer,
  form: formReducer,
  graviton: gravitonReducers,
  holidays: HolidaysReducer,
  interests: InterestReducer,
  investmentProduct: InvestmentProduct,
  jobProcessing: JobProcessingReducer,
  leadHistory: LeadHistoryReducer,
  leadqualification: Leadqualification,
  leadMassMutation: LeadMassMutationReducers,
  leadsSwitchImportCsv: LeadsSwitchImportCsvReducer,
  leadsSwitchImportImport: LeadsSwitchImportImportReducer,
  leadswitch: LeadSwitch,
  loaderTrigger: loaderTriggerReducer,
  login: loginReducer,
  logout: logoutReducer,
  module: moduleReducers,
  noticeImportDelete: NoticeImportDeleteReducer,
  noticeImportExamples: NoticeImportExamplesReducer,
  noticeImportFileListLoad: NoticeImportFileListLoadReducer,
  noticeImportFilePost: NoticeImportFilePostReducer,
  noticeImportImport: NoticeImportImportReducer,
  noticeImportValidation: NoticeImportValidationReducer,
  paramTransfer: paramTransferReducers,
  permissionGroup: PermissionGroupReducer,
  pos: PromotionsReducer,
  refErrors: RefErrorsReducer,
  replayAuditLog: replayAuditLogReducer,
  setManagement: SetManagementReducer,
  textblock: textblockReducers,
  toast: toastReducer,
  types: TypeReducer,
  uiNotes: uiNotesReducer,
  workguide: WorkguideReducer,
  workguideStatus: WorkguideStatusReducer
});

export default rootReducer;
