import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, size } from 'lodash';
import { FormattedMessage } from 'react-intl';

import './LeadMassMutation.css';
import EditContainer from '../components/EditContainer';
import SearchForm from '../components/LeadMassMutation/Form/Search';
import EditForm from '../components/LeadMassMutation/Form/Edit';
import LeadTable from '../components/LeadMassMutation/Table/Table';
import Section from '../components/LeadMassMutation/Section';
import * as codeActions from '../actions/CodeActions';
import codeMappingActions from '../actions/CodeMappingActions';
import leadMassMutationActions from '../actions/LeadMassMutation/Actions';

class LeadMassMutation extends React.Component {
  constructor(props) {
    super(props);

    this.onInit();

    this.onSubmitSearchForm = this.onSubmitSearchForm.bind(this);
    this.onSubmitEditForm = this.onSubmitEditForm.bind(this);
  }

  /**
   * Load necessary data
   *
   * @return  void
   */
  onInit() {
    const {
      codeActions,
      codeMappingActions,
      leadMassMutationActions
    } = this.props;

    codeActions.codesRequest(['orderOrigin', 'customerTaskType', 'customerTaskStatus']);
    codeMappingActions.codeMappingsRequest();
    leadMassMutationActions.configRequest();
    leadMassMutationActions.consultantsRequest();
    leadMassMutationActions.productsRequest();
  }

  /**
   * Submit the search form
   *
   * @return  void
   */
  onSubmitSearchForm({ formData }) {
    const { leadMassMutationActions } = this.props;

    leadMassMutationActions.leadsRequest({ formData });
    leadMassMutationActions.selectedLeadsReset();
  }

  /**
   * Submit the edit form
   *
   * @param   {Object}  formData  Form data
   *
   * @return  void
   */
  onSubmitEditForm({ formData }) {
    const {
      leadMassMutationActions,
      leads,
      selectedLeads,
      session
    } = this.props;

    const filtered = get(leads, 'data', []).filter((l) => get(selectedLeads, 'data', []).includes(l.id));

    leadMassMutationActions.editFormSaveRequest({ leads: filtered, formData, consultantId: get(session, 'id') });
  }

  /**
   * Render the edit container content
   *
   * @return {ReactElement} markup
   */
  renderContent() {
    const {
      saving,
      selectedLeads
    } = this.props;

    return (
      <div className="lead-mass-mutation">
        <Section
          disabled={saving}
          title={<FormattedMessage id="LeadMassMutation.SearchForm.Title" />}
        >
          <SearchForm onSubmit={this.onSubmitSearchForm} />
        </Section>

        <Section
          disabled={saving}
          title={<FormattedMessage id="LeadMassMutation.List.Title" />}
        >
          <LeadTable />
        </Section>

        <Section
          disabled={saving}
          title={<FormattedMessage id="LeadMassMutation.EditForm.Title" />}
        >
          <EditForm
            disabled={size(selectedLeads.data) === 0}
            onSubmit={this.onSubmitEditForm}
          />
        </Section>
      </div>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { requesting } = this.props;

    return (
      <EditContainer
        requesting={requesting}
        title={<FormattedMessage id="LeadMassMutation.Title" />}
        body={this.renderContent()}
      />
    );
  }
}

LeadMassMutation.propTypes = {
  codeActions: PropTypes.object.isRequired,
  codeMappingActions: PropTypes.object.isRequired,
  leadMassMutationActions: PropTypes.object.isRequired,
  session: PropTypes.object,
  leads: PropTypes.object,
  requesting: PropTypes.bool,
  saving: PropTypes.bool,
  selectedLeads: PropTypes.object,
};

LeadMassMutation.defaultProps = {
  leads: {},
  requesting: false,
  saving: false,
  selectedLeads: {},
  session: {}
};

function isRequesting(state) {
  return (
    get(state, 'codes.requesting', false),
    get(state, 'codeMapping.mappings.requesting', false),
    get(state, 'leadMassMutation.config.requesting', false),
    get(state, 'leadMassMutation.consultants.requesting', false),
    get(state, 'leadMassMutation.locations.requesting', false),
    get(state, 'leadMassMutation.regions.requesting', false),
    get(state, 'leadMassMutation.products.requesting', false)
  );
}

function mapStateToProps(state, ownProps) {
  return {
    leads: state.leadMassMutation.leads,
    requesting: isRequesting(state, ownProps),
    saving: get(state, 'leadMassMutation.editForm.saving', false),
    selectedLeads: state.leadMassMutation.selectedLeads,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    codeActions: bindActionCreators(codeActions, dispatch),
    codeMappingActions: bindActionCreators(codeMappingActions, dispatch),
    leadMassMutationActions: bindActionCreators(leadMassMutationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadMassMutation);
