import { Reducer } from '@evoja-web/redaction';
import { get } from 'lodash';
import update from 'immutability-helper';

import action from '../../actions/ReplayAuditLog/AuditLog';

const {
  AUDIT_LOG_ERROR,
  AUDIT_LOG_REQUEST,
  AUDIT_LOG_FULFILLED
} = action;

function Record({ data, currentRecords = {} }) {
  const body = get(data, 'requestBody', '{}');
  const requestUri = get(data, 'requestUri');

  return {
    ...data,
    body: JSON.parse(body),
    current: get(currentRecords, `${requestUri}.data`),
    error:  get(currentRecords, `${requestUri}.error`)
  }
}

function onAuditLogRequest(state) {
  return update(state, {
    prepared: {
      data: { $set: [] }
    }
  });
}

function onAuditLogFulfilled(state, action) {
  const payload = get(action, 'payload', []);
  const currentRecords = get(state, 'currentRecords', {});

  const records = payload.map((data) => Record({ data, currentRecords }))

  return update(state, {
    prepared: {
      data: { $set: records }
    }
  });
}

function onAuditLogError(state) {
  return update(state, {
    prepared: {
      data: { $set: [] }
    }
  });
}

const defaultState = {
  data: []
};

const methods = {
  [AUDIT_LOG_REQUEST]: onAuditLogRequest,
  [AUDIT_LOG_FULFILLED]: onAuditLogFulfilled,
  [AUDIT_LOG_ERROR]: onAuditLogError
};

export const reducer = Reducer('prepared', methods, defaultState);

export default reducer.get();
