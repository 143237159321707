import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import cl from 'classnames';
import { Row, Col, Alert } from 'react-bootstrap';
import { Checkbox } from '@evoja-web/react-form';
import moment from 'moment';

import './BodyRow.css';
import Detail from './Detail';

class LeadMassMutationTableBodyRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };

    this.onExplapse = this.onExplapse.bind(this);
  }

  /**
   * Expand / collapse the row
   *
   * @return  void
   */
  onExplapse() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const {
      className,
      consultants,
      isSelected,
      language,
      lead,
      onSelect,
      update
    } = this.props;
    const consultant = get(consultants, 'data', []).find((c) => get(c, 'id') === get(lead, 'customer.consultantId'));
    const staffMember = get(consultants, 'data', []).find((c) => get(c, 'id') === get(lead, 'staffMemberId'));
    const classNames = cl(
      'lead-mass-mutation--table--body-row',
      className,
      {
        'lead-mass-mutation--table--body-row--pending': get(update, 'requesting', false),
        'lead-mass-mutation--table--body-row--success': get(update, 'fulfilled', false),
        'lead-mass-mutation--table--body-row--erroneous': get(update, 'error') instanceof Error
      }
    );

    return (
      <div className={classNames}>
        <Row
          className="lead-mass-mutation--table--body-row--main"
          onClick={this.onExplapse}
        >
          <Col lg={1} md={1} className="lead-mass-mutation--table--body-row--main--first-col">
            <Checkbox
              id={`select-${lead.id}`}
              onChange={() => onSelect({ lead })}
              value={isSelected}
            />

            <div>
              {get(lead, 'customer.customerNumberAlternate')}
            </div>
          </Col>

          <Col lg={2} md={2}>
            {get(lead, 'customer.designation')}
          </Col>

          <Col lg={2} md={2}>
            {`${get(consultant, 'firstName', '')} ${get(consultant, 'lastName', '')} (${get(consultant, 'username', '')})`}
          </Col>

          <Col lg={2} md={2}>
            {`${get(staffMember, 'firstName', '')} ${get(staffMember, 'lastName', '')} (${get(staffMember, 'username', '')})`}
          </Col>

          <Col lg={1} md={1}>
            {get(lead, `orderOrigin.text.${language}`)}
          </Col>

          <Col lg={1} md={1}>
            {get(lead, `customerTaskType.text.${language}`)}
          </Col>

          <Col lg={1} md={1}>
            {get(lead, `customerTaskStatus.text.${language}`)}
          </Col>

          <Col lg={1} md={1}>
            {moment(lead.dueDate).format('L')}
          </Col>

          <Col lg={1} md={1} className="lead-mass-mutation--table--body-row--main--last-col">
            <div>
              {moment(lead.expireDate).format('L')}
            </div>

            <div className={cl({
              mdi: true,
              'pull-right': true,
              'mdi-chevron-down': collapsed,
              'mdi-chevron-up': !collapsed
            })}
            />
          </Col>
        </Row>

        <Row className={cl({
          'lead-mass-mutation--table--body-row--expanded': true,
          'lead-mass-mutation--table--body-row--expanded--collapsed': collapsed
        })}
        >
          <Col lg={12} md={12}>
            {(get(update, 'error') instanceof Error) && (
              <Alert
                bsStyle="danger"
                className="lead-mass-mutation--table--body-row--expanded--error"
              >
                {get(update, 'error.message', '')}
              </Alert>
            )}
          </Col>

          <Col lg={12} md={12}>
            <Detail
              consultants={consultants}
              language={language}
              lead={lead}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

LeadMassMutationTableBodyRow.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  consultants: PropTypes.object,
  isSelected: PropTypes.bool,
  language: PropTypes.string,
  lead: PropTypes.object,
  onSelect: PropTypes.func,
  update: PropTypes.object
};

LeadMassMutationTableBodyRow.defaultProps = {
  className: undefined,
  consultants: {},
  isSelected: false,
  language: 'de',
  lead: {},
  onSelect: noop,
  update: {}
};

export default LeadMassMutationTableBodyRow;
