import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import configAction from '../../actions/LeadMassMutation/Config';

const {
  CONFIG_PENDING,
  CONFIG_FULFILLED,
  CONFIG_REJECTED
} = configAction;

export default function* getConfig() {
  yield put({ type: CONFIG_PENDING });

  try {
    const url = '/work/leadqualification/?limit(1000)';
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: CONFIG_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONFIG_REJECTED, error });

    return error;
  }
}
